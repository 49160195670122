import { Banner, BannerVariant } from '@krakentech/coral';
import { isWithinInterval } from 'date-fns';
import { FC, ReactNode, useEffect, useState } from 'react';
import { NODE_PARAGRAPH, render } from 'storyblok-rich-text-react-renderer';

import { useAffiliatesContext } from '@/components/contexts/affiliates';
import {
	CMSSparkles,
	CMSSparklesProps,
} from '@/components/storyblok/bloks/CMSSparkles';
import { BannerItemFragment } from '@/services/graphql-storyblok';

type CustomBannerProps = {
	content: BannerItemFragment['content'];
	variant?: BannerVariant;
};

export const CustomBanner: FC<CustomBannerProps> = ({ content, variant }) => {
	const [
		shouldShowBannerForCurrentCustomerType,
		setShouldShowBannerForCurrentCustomerType,
	] = useState(false);
	const { isAffiliatesReady, salesChannel } = useAffiliatesContext();

	// Check if custom banner is within display period if one is specified. Otherwise just return true.
	const isWithinProvidedDisplayPeriod =
		content?.display_start_date && content.display_end_date
			? isWithinInterval(Date.now(), {
					start: new Date(content.display_start_date),
					end: new Date(content.display_end_date),
				})
			: true;

	useEffect(() => {
		if (content?.restrict_to_d2c) {
			if (isAffiliatesReady) {
				/* We assume that a customer with no affiliateSalesChannel or 'DIRECT' is a D2C customer.
				 * If the customer has any other affiliateSalesChannel, we assume they are not a D2C customer and so we do not show the banner. */
				if (!salesChannel || salesChannel === 'DIRECT') {
					setShouldShowBannerForCurrentCustomerType(true);
				}
			}
		} else {
			// Show banner for everyone when not restricted to D2C
			setShouldShowBannerForCurrentCustomerType(true);
		}
	}, [content?.restrict_to_d2c, isAffiliatesReady]);

	return content?.should_display_banner &&
		isWithinProvidedDisplayPeriod &&
		shouldShowBannerForCurrentCustomerType ? (
		<Banner variant={variant}>
			{render(content?.banner_content, {
				blokResolvers: {
					// @ts-expect-error because props are now typed as Record<string, unknown> in the library
					['sparkles']: (blok: CMSSparklesProps['blok']) => (
						<CMSSparkles blok={blok} />
					),
				},
				nodeResolvers: {
					[NODE_PARAGRAPH]: (children: ReactNode) => (
						<span className="inline-block">{children}</span>
					),
				},
			})}
		</Banner>
	) : null;
};
