/**
 * "CAMELCaseIsHumpy" => "CAMEL Case Is Humpy"
 * "soWeWontForget" => "So We Wont Forget"
 * "happy30001Friends" => "Happy 30001 Friends"
 *
 * Uses regex expressions to capture and replace camel case delimiters with spaces, then capitalizes the first letter of the string, effectively turning camelCase into Title Case.
 *
 */
export const camelCaseToTitleCase = (camelCaseString: string): string => {
	return (
		camelCaseString.charAt(0).toUpperCase() +
		camelCaseString
			.slice(1) // Capitalize the first character of string
			.replace(/([a-z])([A-Z0-9])/g, '$1 $2') // Insert space between lower followed by upper/digit
			.replace(/([A-Z0-9])([A-Z0-9])([a-z])/g, '$1 $2$3') // Insert space between lower preceded by two upper/digit
	);
};
