export const BANK_CODES: Array<{ code: string; name: string }> = [
	{ code: '0001', name: 'みずほ銀行' },
	{ code: '0005', name: '三菱ＵＦＪ銀行' },
	{ code: '0009', name: '三井住友銀行' },
	{ code: '0010', name: 'りそな銀行' },
	{ code: '0017', name: '埼玉りそな銀行' },
	{ code: '0033', name: 'ＰａｙＰａｙ銀行' },
	{ code: '0034', name: 'セブン銀行' },
	{ code: '0035', name: 'ソニー銀行' },
	{ code: '0036', name: '楽天銀行' },
	{ code: '0038', name: '住信ＳＢＩネット銀行' },
	{ code: '0039', name: 'ａｕじぶん銀行' },
	{ code: '0040', name: 'イオン銀行' },
	{ code: '0116', name: '北海道銀行' },
	{ code: '0117', name: '青森銀行' },
	{ code: '0118', name: 'みちのく銀行' },
	{ code: '0119', name: '秋田銀行' },
	{ code: '0120', name: '北都銀行' },
	{ code: '0121', name: '荘内銀行' },
	{ code: '0122', name: '山形銀行' },
	{ code: '0123', name: '岩手銀行' },
	{ code: '0124', name: '東北銀行' },
	{ code: '0125', name: '七十七銀行' },
	{ code: '0126', name: '東邦銀行' },
	{ code: '0128', name: '群馬銀行' },
	{ code: '0129', name: '足利銀行' },
	{ code: '0130', name: '常陽銀行' },
	{ code: '0131', name: '筑波銀行' },
	{ code: '0133', name: '武蔵野銀行' },
	{ code: '0134', name: '千葉銀行' },
	{ code: '0135', name: '千葉興業銀行' },
	{ code: '0137', name: 'きらぼし銀行' },
	{ code: '0138', name: '横浜銀行' },
	{ code: '0140', name: '第四北越銀行' },
	{ code: '0142', name: '山梨中央銀行' },
	{ code: '0143', name: '八十二銀行' },
	{ code: '0144', name: '北陸銀行' },
	{ code: '0145', name: '富山銀行' },
	{ code: '0146', name: '北國銀行' },
	{ code: '0147', name: '福井銀行' },
	{ code: '0149', name: '静岡銀行' },
	{ code: '0150', name: 'スルガ銀行' },
	{ code: '0151', name: '清水銀行' },
	{ code: '0152', name: '大垣共立銀行' },
	{ code: '0153', name: '十六銀行' },
	{ code: '0154', name: '三十三銀行' },
	{ code: '0155', name: '百五銀行' },
	{ code: '0157', name: '滋賀銀行' },
	{ code: '0158', name: '京都銀行' },
	{ code: '0159', name: '関西みらい銀行' },
	{ code: '0161', name: '池田泉州銀行' },
	{ code: '0162', name: '南都銀行' },
	{ code: '0163', name: '紀陽銀行' },
	{ code: '0164', name: '但馬銀行' },
	{ code: '0166', name: '鳥取銀行' },
	{ code: '0167', name: '山陰合同銀行' },
	{ code: '0168', name: '中国銀行' },
	{ code: '0169', name: '広島銀行' },
	{ code: '0170', name: '山口銀行' },
	{ code: '0172', name: '阿波銀行' },
	{ code: '0173', name: '百十四銀行' },
	{ code: '0174', name: '伊予銀行' },
	{ code: '0175', name: '四国銀行' },
	{ code: '0177', name: '福岡銀行' },
	{ code: '0178', name: '筑邦銀行' },
	{ code: '0179', name: '佐賀銀行' },
	{ code: '0181', name: '十八親和銀行' },
	{ code: '0182', name: '肥後銀行' },
	{ code: '0183', name: '大分銀行' },
	{ code: '0184', name: '宮崎銀行' },
	{ code: '0185', name: '鹿児島銀行' },
	{ code: '0187', name: '琉球銀行' },
	{ code: '0188', name: '沖縄銀行' },
	{ code: '0190', name: '西日本シティ銀行' },
	{ code: '0191', name: '北九州銀行' },
	{ code: '0310', name: 'ＧＭＯあおぞらネット銀行' },
	{ code: '0397', name: 'ＳＢＩ新生銀行' },
	{ code: '0398', name: 'あおぞら銀行' },
	{ code: '0501', name: '北洋銀行' },
	{ code: '0508', name: 'きらやか銀行' },
	{ code: '0509', name: '北日本銀行' },
	{ code: '0512', name: '仙台銀行' },
	{ code: '0513', name: '福島銀行' },
	{ code: '0514', name: '大東銀行' },
	{ code: '0516', name: '東和銀行' },
	{ code: '0517', name: '栃木銀行' },
	{ code: '0522', name: '京葉銀行' },
	{ code: '0532', name: '大光銀行' },
	{ code: '0533', name: '長野銀行' },
	{ code: '0534', name: '富山第一銀行' },
	{ code: '0537', name: '福邦銀行' },
	{ code: '0538', name: '静岡中央銀行' },
	{ code: '0542', name: '愛知銀行' },
	{ code: '0543', name: '名古屋銀行' },
	{ code: '0544', name: '中京銀行' },
	{ code: '0562', name: 'みなと銀行' },
	{ code: '0565', name: '島根銀行' },
	{ code: '0566', name: 'トマト銀行' },
	{ code: '0569', name: 'もみじ銀行' },
	{ code: '0570', name: '西京銀行' },
	{ code: '0572', name: '徳島大正銀行' },
	{ code: '0573', name: '香川銀行' },
	{ code: '0576', name: '愛媛銀行' },
	{ code: '0578', name: '高知銀行' },
	{ code: '0587', name: '熊本銀行' },
	{ code: '0591', name: '宮崎太陽銀行' },
	{ code: '0526', name: '東京スター銀行' },
	{ code: '0530', name: '神奈川銀行' },
	{ code: '0582', name: '福岡中央銀行' },
	{ code: '0583', name: '佐賀共栄銀行' },
	{ code: '0585', name: '長崎銀行' },
	{ code: '0590', name: '豊和銀行' },
	{ code: '0594', name: '南日本銀行' },
	{ code: '0596', name: '沖縄海邦銀行' },
	{ code: '0525', name: '東日本銀行' },
	{ code: '9900', name: 'ゆうちょ銀行' },
	{ code: '1001', name: '北海道信用金庫' },
	{ code: '1003', name: '室蘭信用金庫' },
	{ code: '1004', name: '空知信用金庫' },
	{ code: '1006', name: '苫小牧信用金庫' },
	{ code: '1008', name: '北門信用金庫' },
	{ code: '1009', name: '伊達信用金庫' },
	{ code: '1010', name: '北空知信用金庫' },
	{ code: '1011', name: '日高信用金庫' },
	{ code: '1013', name: '渡島信用金庫' },
	{ code: '1014', name: '道南うみ街信用金庫' },
	{ code: '1020', name: '旭川信用金庫' },
	{ code: '1021', name: '稚内信用金庫' },
	{ code: '1022', name: '留萌信用金庫' },
	{ code: '1024', name: '北星信用金庫' },
	{ code: '1026', name: '帯広信用金庫' },
	{ code: '1027', name: '釧路信用金庫' },
	{ code: '1028', name: '大地みらい信用金庫' },
	{ code: '1030', name: '北見信用金庫' },
	{ code: '1031', name: '網走信用金庫' },
	{ code: '1033', name: '遠軽信用金庫' },
	{ code: '1104', name: '東奥信用金庫' },
	{ code: '1105', name: '青い森信用金庫' },
	{ code: '1120', name: '秋田信用金庫' },
	{ code: '1123', name: '羽後信用金庫' },
	{ code: '1140', name: '山形信用金庫' },
	{ code: '1141', name: '米沢信用金庫' },
	{ code: '1142', name: '鶴岡信用金庫' },
	{ code: '1143', name: '新庄信用金庫' },
	{ code: '1150', name: '盛岡信用金庫' },
	{ code: '1152', name: '宮古信用金庫' },
	{ code: '1153', name: '一関信用金庫' },
	{ code: '1154', name: '北上信用金庫' },
	{ code: '1155', name: '花巻信用金庫' },
	{ code: '1156', name: '水沢信用金庫' },
	{ code: '1170', name: '杜の都信用金庫' },
	{ code: '1171', name: '宮城第一信用金庫' },
	{ code: '1172', name: '石巻信用金庫' },
	{ code: '1174', name: '仙南信用金庫' },
	{ code: '1175', name: '気仙沼信用金庫' },
	{ code: '1181', name: '会津信用金庫' },
	{ code: '1182', name: '郡山信用金庫' },
	{ code: '1184', name: '白河信用金庫' },
	{ code: '1185', name: '須賀川信用金庫' },
	{ code: '1186', name: 'ひまわり信用金庫' },
	{ code: '1188', name: 'あぶくま信用金庫' },
	{ code: '1189', name: '二本松信用金庫' },
	{ code: '1190', name: '福島信用金庫' },
	{ code: '1203', name: '高崎信用金庫' },
	{ code: '1204', name: '桐生信用金庫' },
	{ code: '1206', name: 'アイオー信用金庫' },
	{ code: '1208', name: '利根郡信用金庫' },
	{ code: '1209', name: '館林信用金庫' },
	{ code: '1210', name: '北群馬信用金庫' },
	{ code: '1211', name: 'しののめ信用金庫' },
	{ code: '1221', name: '足利小山信用金庫' },
	{ code: '1222', name: '栃木信用金庫' },
	{ code: '1223', name: '鹿沼相互信用金庫' },
	{ code: '1224', name: '佐野信用金庫' },
	{ code: '1225', name: '大田原信用金庫' },
	{ code: '1227', name: '烏山信用金庫' },
	{ code: '1240', name: '水戸信用金庫' },
	{ code: '1242', name: '結城信用金庫' },
	{ code: '1250', name: '埼玉縣信用金庫' },
	{ code: '1251', name: '川口信用金庫' },
	{ code: '1252', name: '青木信用金庫' },
	{ code: '1253', name: '飯能信用金庫' },
	{ code: '1260', name: '千葉信用金庫' },
	{ code: '1261', name: '銚子信用金庫' },
	{ code: '1262', name: '東京ベイ信用金庫' },
	{ code: '1264', name: '館山信用金庫' },
	{ code: '1267', name: '佐原信用金庫' },
	{ code: '1280', name: '横浜信用金庫' },
	{ code: '1281', name: 'かながわ信用金庫' },
	{ code: '1282', name: '湘南信用金庫' },
	{ code: '1283', name: '川崎信用金庫' },
	{ code: '1286', name: '平塚信用金庫' },
	{ code: '1288', name: 'さがみ信用金庫' },
	{ code: '1289', name: '中栄信用金庫' },
	{ code: '1290', name: '中南信用金庫' },
	{ code: '1303', name: '朝日信用金庫' },
	{ code: '1305', name: '興産信用金庫' },
	{ code: '1310', name: 'さわやか信用金庫' },
	{ code: '1311', name: '東京シティ信用金庫' },
	{ code: '1319', name: '芝信用金庫' },
	{ code: '1320', name: '東京東信用金庫' },
	{ code: '1321', name: '東栄信用金庫' },
	{ code: '1323', name: '亀有信用金庫' },
	{ code: '1326', name: '小松川信用金庫' },
	{ code: '1327', name: '足立成和信用金庫' },
	{ code: '1333', name: '東京三協信用金庫' },
	{ code: '1336', name: '西京信用金庫' },
	{ code: '1341', name: '西武信用金庫' },
	{ code: '1344', name: '城南信用金庫' },
	{ code: '1345', name: '昭和信用金庫' },
	{ code: '1346', name: '目黒信用金庫' },
	{ code: '1348', name: '世田谷信用金庫' },
	{ code: '1349', name: '東京信用金庫' },
	{ code: '1351', name: '城北信用金庫' },
	{ code: '1352', name: '瀧野川信用金庫' },
	{ code: '1356', name: '巣鴨信用金庫' },
	{ code: '1358', name: '青梅信用金庫' },
	{ code: '1360', name: '多摩信用金庫' },
	{ code: '1370', name: '新潟信用金庫' },
	{ code: '1371', name: '長岡信用金庫' },
	{ code: '1373', name: '三条信用金庫' },
	{ code: '1374', name: '新発田信用金庫' },
	{ code: '1375', name: '柏崎信用金庫' },
	{ code: '1376', name: '上越信用金庫' },
	{ code: '1377', name: '新井信用金庫' },
	{ code: '1379', name: '村上信用金庫' },
	{ code: '1380', name: '加茂信用金庫' },
	{ code: '1385', name: '甲府信用金庫' },
	{ code: '1386', name: '山梨信用金庫' },
	{ code: '1390', name: '長野信用金庫' },
	{ code: '1391', name: '松本信用金庫' },
	{ code: '1392', name: '上田信用金庫' },
	{ code: '1393', name: '諏訪信用金庫' },
	{ code: '1394', name: '飯田信用金庫' },
	{ code: '1396', name: 'アルプス中央信用金庫' },
	{ code: '1401', name: '富山信用金庫' },
	{ code: '1402', name: '高岡信用金庫' },
	{ code: '1404', name: '新湊信用金庫' },
	{ code: '1405', name: 'にいかわ信用金庫' },
	{ code: '1406', name: '氷見伏木信用金庫' },
	{ code: '1412', name: '砺波信用金庫' },
	{ code: '1413', name: '石動信用金庫' },
	{ code: '1440', name: '金沢信用金庫' },
	{ code: '1442', name: 'のと共栄信用金庫' },
	{ code: '1444', name: 'はくさん信用金庫' },
	{ code: '1448', name: '興能信用金庫' },
	{ code: '1470', name: '福井信用金庫' },
	{ code: '1471', name: '敦賀信用金庫' },
	{ code: '1473', name: '小浜信用金庫' },
	{ code: '1475', name: '越前信用金庫' },
	{ code: '1501', name: 'しずおか焼津信用金庫' },
	{ code: '1502', name: '静清信用金庫' },
	{ code: '1503', name: '浜松磐田信用金庫' },
	{ code: '1505', name: '沼津信用金庫' },
	{ code: '1506', name: '三島信用金庫' },
	{ code: '1507', name: '富士宮信用金庫' },
	{ code: '1513', name: '島田掛川信用金庫' },
	{ code: '1515', name: '富士信用金庫' },
	{ code: '1517', name: '遠州信用金庫' },
	{ code: '1530', name: '岐阜信用金庫' },
	{ code: '1531', name: '大垣西濃信用金庫' },
	{ code: '1532', name: '高山信用金庫' },
	{ code: '1533', name: '東濃信用金庫' },
	{ code: '1534', name: '関信用金庫' },
	{ code: '1538', name: '八幡信用金庫' },
	{ code: '1550', name: '愛知信用金庫' },
	{ code: '1551', name: '豊橋信用金庫' },
	{ code: '1552', name: '岡崎信用金庫' },
	{ code: '1553', name: 'いちい信用金庫' },
	{ code: '1554', name: '瀬戸信用金庫' },
	{ code: '1555', name: '半田信用金庫' },
	{ code: '1556', name: '知多信用金庫' },
	{ code: '1557', name: '豊川信用金庫' },
	{ code: '1559', name: '豊田信用金庫' },
	{ code: '1560', name: '碧海信用金庫' },
	{ code: '1561', name: '西尾信用金庫' },
	{ code: '1562', name: '蒲郡信用金庫' },
	{ code: '1563', name: '尾西信用金庫' },
	{ code: '1565', name: '中日信用金庫' },
	{ code: '1566', name: '東春信用金庫' },
	{ code: '1580', name: '津信用金庫' },
	{ code: '1581', name: '北伊勢上野信用金庫' },
	{ code: '1583', name: '桑名三重信用金庫' },
	{ code: '1585', name: '紀北信用金庫' },
	{ code: '1602', name: '滋賀中央信用金庫' },
	{ code: '1603', name: '長浜信用金庫' },
	{ code: '1604', name: '湖東信用金庫' },
	{ code: '1610', name: '京都信用金庫' },
	{ code: '1611', name: '京都中央信用金庫' },
	{ code: '1620', name: '京都北都信用金庫' },
	{ code: '1630', name: '大阪信用金庫' },
	{ code: '1633', name: '大阪厚生信用金庫' },
	{ code: '1635', name: '大阪シティ信用金庫' },
	{ code: '1636', name: '大阪商工信用金庫' },
	{ code: '1643', name: '永和信用金庫' },
	{ code: '1645', name: '北おおさか信用金庫' },
	{ code: '1656', name: '枚方信用金庫' },
	{ code: '1666', name: '奈良信用金庫' },
	{ code: '1667', name: '大和信用金庫' },
	{ code: '1668', name: '奈良中央信用金庫' },
	{ code: '1671', name: '新宮信用金庫' },
	{ code: '1674', name: 'きのくに信用金庫' },
	{ code: '1680', name: '神戸信用金庫' },
	{ code: '1685', name: '姫路信用金庫' },
	{ code: '1686', name: '播州信用金庫' },
	{ code: '1687', name: '兵庫信用金庫' },
	{ code: '1688', name: '尼崎信用金庫' },
	{ code: '1689', name: '日新信用金庫' },
	{ code: '1691', name: '淡路信用金庫' },
	{ code: '1692', name: '但馬信用金庫' },
	{ code: '1694', name: '西兵庫信用金庫' },
	{ code: '1695', name: '中兵庫信用金庫' },
	{ code: '1696', name: '但陽信用金庫' },
	{ code: '1701', name: '鳥取信用金庫' },
	{ code: '1702', name: '米子信用金庫' },
	{ code: '1703', name: '倉吉信用金庫' },
	{ code: '1710', name: 'しまね信用金庫' },
	{ code: '1711', name: '日本海信用金庫' },
	{ code: '1712', name: '島根中央信用金庫' },
	{ code: '1732', name: 'おかやま信用金庫' },
	{ code: '1734', name: '水島信用金庫' },
	{ code: '1735', name: '津山信用金庫' },
	{ code: '1738', name: '玉島信用金庫' },
	{ code: '1740', name: '備北信用金庫' },
	{ code: '1741', name: '吉備信用金庫' },
	{ code: '1743', name: '備前日生信用金庫' },
	{ code: '1750', name: '広島信用金庫' },
	{ code: '1752', name: '呉信用金庫' },
	{ code: '1756', name: 'しまなみ信用金庫' },
	{ code: '1758', name: '広島みどり信用金庫' },
	{ code: '1780', name: '萩山口信用金庫' },
	{ code: '1781', name: '西中国信用金庫' },
	{ code: '1789', name: '東山口信用金庫' },
	{ code: '1801', name: '徳島信用金庫' },
	{ code: '1803', name: '阿南信用金庫' },
	{ code: '1830', name: '高松信用金庫' },
	{ code: '1833', name: '観音寺信用金庫' },
	{ code: '1860', name: '愛媛信用金庫' },
	{ code: '1862', name: '宇和島信用金庫' },
	{ code: '1864', name: '東予信用金庫' },
	{ code: '1866', name: '川之江信用金庫' },
	{ code: '1880', name: '幡多信用金庫' },
	{ code: '1881', name: '高知信用金庫' },
	{ code: '1901', name: '福岡信用金庫' },
	{ code: '1903', name: '福岡ひびき信用金庫' },
	{ code: '1908', name: '大牟田柳川信用金庫' },
	{ code: '1909', name: '筑後信用金庫' },
	{ code: '1910', name: '飯塚信用金庫' },
	{ code: '1913', name: '田川信用金庫' },
	{ code: '1917', name: '大川信用金庫' },
	{ code: '1920', name: '遠賀信用金庫' },
	{ code: '1930', name: '唐津信用金庫' },
	{ code: '1931', name: '佐賀信用金庫' },
	{ code: '1932', name: '伊万里信用金庫' },
	{ code: '1933', name: '九州ひぜん信用金庫' },
	{ code: '1942', name: 'たちばな信用金庫' },
	{ code: '1951', name: '熊本信用金庫' },
	{ code: '1952', name: '熊本第一信用金庫' },
	{ code: '1954', name: '熊本中央信用金庫' },
	{ code: '1955', name: '天草信用金庫' },
	{ code: '1960', name: '大分信用金庫' },
	{ code: '1962', name: '大分みらい信用金庫' },
	{ code: '1968', name: '日田信用金庫' },
	{ code: '1980', name: '宮崎第一信用金庫' },
	{ code: '1982', name: '延岡信用金庫' },
	{ code: '1985', name: '高鍋信用金庫' },
	{ code: '1990', name: '鹿児島信用金庫' },
	{ code: '1991', name: '鹿児島相互信用金庫' },
	{ code: '1993', name: '奄美大島信用金庫' },
	{ code: '1996', name: 'コザ信用金庫' },
	{ code: '2011', name: '北央信用組合' },
	{ code: '2013', name: '札幌中央信用組合' },
	{ code: '2017', name: '函館商工信用組合' },
	{ code: '2024', name: '十勝信用組合' },
	{ code: '2025', name: '釧路信用組合' },
	{ code: '2030', name: '青森県信用組合' },
	{ code: '2060', name: 'あすか信用組合' },
	{ code: '2061', name: '石巻商工信用組合' },
	{ code: '2062', name: '古川信用組合' },
	{ code: '2063', name: '仙北信用組合' },
	{ code: '2075', name: '秋田県信用組合' },
	{ code: '2083', name: '北郡信用組合' },
	{ code: '2084', name: '山形中央信用組合' },
	{ code: '2085', name: '山形第一信用組合' },
	{ code: '2090', name: '福島県商工信用組合' },
	{ code: '2092', name: 'いわき信用組合' },
	{ code: '2095', name: '相双五城信用組合' },
	{ code: '2096', name: '会津商工信用組合' },
	{ code: '2101', name: '茨城県信用組合' },
	{ code: '2122', name: '真岡信用組合' },
	{ code: '2125', name: '那須信用組合' },
	{ code: '2143', name: 'あかぎ信用組合' },
	{ code: '2146', name: '群馬県信用組合' },
	{ code: '2149', name: 'ぐんまみらい信用組合' },
	{ code: '2165', name: '熊谷商工信用組合' },
	{ code: '2167', name: '埼玉信用組合' },
	{ code: '2180', name: '房総信用組合' },
	{ code: '2184', name: '銚子商工信用組合' },
	{ code: '2190', name: '君津信用組合' },
	{ code: '2202', name: '全東栄信用組合' },
	{ code: '2224', name: '東京厚生信用組合' },
	{ code: '2226', name: '東信用組合' },
	{ code: '2229', name: '江東信用組合' },
	{ code: '2231', name: '青和信用組合' },
	{ code: '2235', name: '中ノ郷信用組合' },
	{ code: '2241', name: '共立信用組合' },
	{ code: '2243', name: '七島信用組合' },
	{ code: '2248', name: '大東京信用組合' },
	{ code: '2254', name: '第一勧業信用組合' },
	{ code: '2271', name: '警視庁職員信用組合' },
	{ code: '2274', name: '東京消防信用組合' },
	{ code: '2276', name: '東京都職員信用組合' },
	{ code: '2277', name: 'ハナ信用組合' },
	{ code: '2304', name: '神奈川県医師信用組合' },
	{ code: '2305', name: '神奈川県歯科医師信用組合' },
	{ code: '2306', name: '横浜幸銀信用組合' },
	{ code: '2307', name: '横浜華銀信用組合' },
	{ code: '2315', name: '小田原第一信用組合' },
	{ code: '2318', name: '相愛信用組合' },
	{ code: '2351', name: '新潟縣信用組合' },
	{ code: '2354', name: '新潟鉄道信用組合' },
	{ code: '2356', name: '興栄信用組合' },
	{ code: '2357', name: 'はばたき信用組合' },
	{ code: '2360', name: '協栄信用組合' },
	{ code: '2361', name: '三條信用組合' },
	{ code: '2362', name: '巻信用組合' },
	{ code: '2363', name: '新潟大栄信用組合' },
	{ code: '2366', name: '糸魚川信用組合' },
	{ code: '2377', name: '山梨県民信用組合' },
	{ code: '2378', name: '都留信用組合' },
	{ code: '2390', name: '長野県信用組合' },
	{ code: '2402', name: '富山県医師信用組合' },
	{ code: '2404', name: '富山県信用組合' },
	{ code: '2411', name: '金沢中央信用組合' },
	{ code: '2430', name: '福泉信用組合' },
	{ code: '2442', name: '愛知商銀信用組合' },
	{ code: '2443', name: '愛知県警察信用組合' },
	{ code: '2448', name: '豊橋商工信用組合' },
	{ code: '2451', name: '愛知県中央信用組合' },
	{ code: '2470', name: '岐阜商工信用組合' },
	{ code: '2471', name: 'イオ信用組合' },
	{ code: '2476', name: '飛騨信用組合' },
	{ code: '2481', name: '益田信用組合' },
	{ code: '2504', name: '滋賀県民信用組合' },
	{ code: '2505', name: '滋賀県信用組合' },
	{ code: '2526', name: '京滋信用組合' },
	{ code: '2540', name: '大同信用組合' },
	{ code: '2541', name: '成協信用組合' },
	{ code: '2548', name: '大阪貯蓄信用組合' },
	{ code: '2549', name: 'のぞみ信用組合' },
	{ code: '2560', name: '大阪府医師信用組合' },
	{ code: '2566', name: '大阪府警察信用組合' },
	{ code: '2567', name: '近畿産業信用組合' },
	{ code: '2580', name: '朝日新聞信用組合' },
	{ code: '2582', name: 'ミレ信用組合' },
	{ code: '2602', name: '兵庫県警察信用組合' },
	{ code: '2605', name: '兵庫県医療信用組合' },
	{ code: '2606', name: '兵庫県信用組合' },
	{ code: '2616', name: '淡陽信用組合' },
	{ code: '2620', name: '兵庫ひまわり信用組合' },
	{ code: '2661', name: '島根益田信用組合' },
	{ code: '2672', name: '朝銀西信用組合' },
	{ code: '2674', name: '笠岡信用組合' },
	{ code: '2680', name: '広島市信用組合' },
	{ code: '2681', name: '広島県信用組合' },
	{ code: '2684', name: '広島商銀信用組合' },
	{ code: '2690', name: '両備信用組合' },
	{ code: '2696', name: '備後信用組合' },
	{ code: '2703', name: '山口県信用組合' },
	{ code: '2721', name: '香川県信用組合' },
	{ code: '2740', name: '土佐信用組合' },
	{ code: '2741', name: '宿毛商銀信用組合' },
	{ code: '2773', name: '福岡県信用組合' },
	{ code: '2803', name: '佐賀東信用組合' },
	{ code: '2808', name: '佐賀西信用組合' },
	{ code: '2820', name: '長崎三菱信用組合' },
	{ code: '2825', name: '西海みずき信用組合' },
	{ code: '2845', name: '熊本県信用組合' },
	{ code: '2870', name: '大分県信用組合' },
	{ code: '2884', name: '宮崎県南部信用組合' },
	{ code: '2890', name: '鹿児島興業信用組合' },
	{ code: '2895', name: '奄美信用組合' },
	{ code: '2951', name: '北海道労働金庫' },
	{ code: '2954', name: '東北労働金庫' },
	{ code: '2963', name: '中央労働金庫' },
	{ code: '2965', name: '新潟県労働金庫' },
	{ code: '2966', name: '長野県労働金庫' },
	{ code: '2968', name: '静岡県労働金庫' },
	{ code: '2970', name: '北陸労働金庫' },
	{ code: '2972', name: '東海労働金庫' },
	{ code: '2978', name: '近畿労働金庫' },
	{ code: '2984', name: '中国労働金庫' },
	{ code: '2987', name: '四国労働金庫' },
	{ code: '2990', name: '九州労働金庫' },
	{ code: '2997', name: '沖縄県労働金庫' },
	{ code: '3001', name: '北海道信用農業協同組合連合会' },
	{ code: '3003', name: '岩手県信用農業協同組合連合会' },
	{ code: '3008', name: '茨城県信用農業協同組合連合会' },
	{ code: '3011', name: '埼玉県信用農業協同組合連合会' },
	{ code: '3013', name: '東京都信用農業協同組合連合会' },
	{ code: '3014', name: '神奈川県信用農業協同組合連合会' },
	{ code: '3015', name: '山梨県信用農業協同組合連合会' },
	{ code: '3016', name: '長野県信用農業協同組合連合会' },
	{ code: '3017', name: '新潟県信用農業協同組合連合会' },
	{ code: '3019', name: '石川県信用農業協同組合連合会' },
	{ code: '3020', name: '岐阜県信用農業協同組合連合会' },
	{ code: '3021', name: '静岡県信用農業協同組合連合会' },
	{ code: '3022', name: '愛知県信用農業協同組合連合会' },
	{ code: '3023', name: '三重県信用農業協同組合連合会' },
	{ code: '3024', name: '福井県信用農業協同組合連合会' },
	{ code: '3025', name: '滋賀県信用農業協同組合連合会' },
	{ code: '3026', name: '京都府信用農業協同組合連合会' },
	{ code: '3027', name: '大阪府信用農業協同組合連合会' },
	{ code: '3028', name: '兵庫県信用農業協同組合連合会' },
	{ code: '3030', name: '和歌山県信用農業協同組合連合会' },
	{ code: '3031', name: '鳥取県信用農業協同組合連合会' },
	{ code: '3034', name: '広島県信用農業協同組合連合会' },
	{ code: '3035', name: '山口県信用農業協同組合連合会' },
	{ code: '3036', name: '徳島県信用農業協同組合連合会' },
	{ code: '3037', name: '香川県信用農業協同組合連合会' },
	{ code: '3038', name: '愛媛県信用農業協同組合連合会' },
	{ code: '3039', name: '高知県信用農業協同組合連合会' },
	{ code: '3040', name: '福岡県信用農業協同組合連合会' },
	{ code: '3041', name: '佐賀県信用農業協同組合連合会' },
	{ code: '3044', name: '大分県信用農業協同組合連合会' },
	{ code: '3045', name: '宮崎県信用農業協同組合連合会' },
	{ code: '3046', name: '鹿児島県信用農業協同組合連合会' },
	{ code: '3056', name: '北檜山町農業協同組合' },
	{ code: '3058', name: '今金町農業協同組合' },
	{ code: '3066', name: '函館市亀田農業協同組合' },
	{ code: '3068', name: '新函館農業協同組合' },
	{ code: '3086', name: 'ようてい農業協同組合' },
	{ code: '3087', name: 'きょうわ農業協同組合' },
	{ code: '3094', name: '新おたる農業協同組合' },
	{ code: '3095', name: '余市町農業協同組合' },
	{ code: '3103', name: 'とうや湖農業協同組合' },
	{ code: '3107', name: '伊達市農業協同組合' },
	{ code: '3112', name: 'とまこまい広域農業協同組合' },
	{ code: '3114', name: '鵡川農業協同組合' },
	{ code: '3120', name: 'びらとり農業協同組合' },
	{ code: '3122', name: '門別町農業協同組合' },
	{ code: '3126', name: 'みついし農業協同組合' },
	{ code: '3133', name: '札幌市農業協同組合' },
	{ code: '3139', name: '道央農業協同組合' },
	{ code: '3142', name: '石狩市農業協同組合' },
	{ code: '3145', name: '北石狩農業協同組合' },
	{ code: '3147', name: '新篠津村農業協同組合' },
	{ code: '3154', name: 'サツラク農業協同組合' },
	{ code: '3156', name: 'いわみざわ農業協同組合' },
	{ code: '3161', name: '南幌町農業協同組合' },
	{ code: '3164', name: '美唄市農業協同組合' },
	{ code: '3165', name: '峰延農業協同組合' },
	{ code: '3168', name: '月形町農業協同組合' },
	{ code: '3170', name: 'ながぬま農業協同組合' },
	{ code: '3172', name: 'そらち南農業協同組合' },
	{ code: '3173', name: '夕張市農業協同組合' },
	{ code: '3175', name: '新砂川農業協同組合' },
	{ code: '3177', name: 'たきかわ農業協同組合' },
	{ code: '3181', name: 'ピンネ農業協同組合' },
	{ code: '3188', name: '北いぶき農業協同組合' },
	{ code: '3189', name: 'きたそらち農業協同組合' },
	{ code: '3202', name: 'るもい農業協同組合' },
	{ code: '3208', name: '幌延町農業協同組合' },
	{ code: '3210', name: 'あさひかわ農業協同組合' },
	{ code: '3214', name: 'たいせつ農業協同組合' },
	{ code: '3219', name: '東神楽農業協同組合' },
	{ code: '3220', name: '東旭川農業協同組合' },
	{ code: '3223', name: '当麻農業協同組合' },
	{ code: '3224', name: '比布町農業協同組合' },
	{ code: '3225', name: '上川中央農業協同組合' },
	{ code: '3227', name: '東川町農業協同組合' },
	{ code: '3228', name: '美瑛町農業協同組合' },
	{ code: '3231', name: 'ふらの農業協同組合' },
	{ code: '3238', name: '北ひびき農業協同組合' },
	{ code: '3244', name: '道北なよろ農業協同組合' },
	{ code: '3248', name: '北はるか農業協同組合' },
	{ code: '3254', name: '稚内農業協同組合' },
	{ code: '3257', name: '北宗谷農業協同組合' },
	{ code: '3259', name: '東宗谷農業協同組合' },
	{ code: '3261', name: '宗谷南農業協同組合' },
	{ code: '3264', name: '帯広市川西農業協同組合' },
	{ code: '3265', name: '帯広大正農業協同組合' },
	{ code: '3266', name: '中札内村農業協同組合' },
	{ code: '3267', name: '更別村農業協同組合' },
	{ code: '3268', name: '忠類農業協同組合' },
	{ code: '3269', name: '大樹町農業協同組合' },
	{ code: '3270', name: '広尾町農業協同組合' },
	{ code: '3271', name: '芽室町農業協同組合' },
	{ code: '3273', name: '十勝清水町農業協同組合' },
	{ code: '3275', name: '新得町農業協同組合' },
	{ code: '3276', name: '鹿追町農業協同組合' },
	{ code: '3277', name: '木野農業協同組合' },
	{ code: '3278', name: '音更町農業協同組合' },
	{ code: '3279', name: '士幌町農業協同組合' },
	{ code: '3280', name: '上士幌町農業協同組合' },
	{ code: '3281', name: '札内農業協同組合' },
	{ code: '3282', name: '幕別町農業協同組合' },
	{ code: '3283', name: '十勝池田町農業協同組合' },
	{ code: '3286', name: '豊頃町農業協同組合' },
	{ code: '3287', name: '浦幌町農業協同組合' },
	{ code: '3288', name: '本別町農業協同組合' },
	{ code: '3289', name: '足寄町農業協同組合' },
	{ code: '3290', name: '陸別町農業協同組合' },
	{ code: '3297', name: '北オホーツク農業協同組合' },
	{ code: '3301', name: 'オホーツクはまなす農業協同組合' },
	{ code: '3303', name: '佐呂間町農業協同組合' },
	{ code: '3305', name: '湧別町農業協同組合' },
	{ code: '3306', name: 'えんゆう農業協同組合' },
	{ code: '3317', name: 'きたみらい農業協同組合' },
	{ code: '3319', name: '津別町農業協同組合' },
	{ code: '3320', name: '美幌町農業協同組合' },
	{ code: '3321', name: '女満別町農業協同組合' },
	{ code: '3322', name: '常呂町農業協同組合' },
	{ code: '3326', name: 'オホーツク網走農業協同組合' },
	{ code: '3328', name: '小清水町農業協同組合' },
	{ code: '3329', name: 'しれとこ斜里農業協同組合' },
	{ code: '3330', name: '清里町農業協同組合' },
	{ code: '3334', name: '釧路太田農業協同組合' },
	{ code: '3335', name: '浜中町農業協同組合' },
	{ code: '3336', name: '標茶町農業協同組合' },
	{ code: '3337', name: '摩周湖農業協同組合' },
	{ code: '3338', name: '阿寒農業協同組合' },
	{ code: '3339', name: '釧路丹頂農業協同組合' },
	{ code: '3348', name: '標津町農業協同組合' },
	{ code: '3349', name: '中標津町農業協同組合' },
	{ code: '3350', name: '計根別農業協同組合' },
	{ code: '3354', name: '道東あさひ農業協同組合' },
	{ code: '3358', name: '中春別農業協同組合' },
	{ code: '3373', name: '青森農業協同組合' },
	{ code: '3387', name: 'つがる弘前農業協同組合' },
	{ code: '3390', name: '相馬村農業協同組合' },
	{ code: '3407', name: '津軽みらい農業協同組合' },
	{ code: '3421', name: 'つがるにしきた農業協同組合' },
	{ code: '3442', name: 'ごしょつがる農業協同組合' },
	{ code: '3455', name: '十和田おいらせ農業協同組合' },
	{ code: '3469', name: 'ゆうき青森農業協同組合' },
	{ code: '3474', name: 'おいらせ農業協同組合' },
	{ code: '3488', name: '八戸農業協同組合' },
	{ code: '3517', name: '新岩手農業協同組合' },
	{ code: '3541', name: '岩手中央農業協同組合' },
	{ code: '3553', name: '花巻農業協同組合' },
	{ code: '3572', name: '岩手ふるさと農業協同組合' },
	{ code: '3579', name: '岩手江刺農業協同組合' },
	{ code: '3590', name: 'いわて平泉農業協同組合' },
	{ code: '3598', name: '大船渡市農業協同組合' },
	{ code: '3636', name: '仙台農業協同組合' },
	{ code: '3647', name: '岩沼市農業協同組合' },
	{ code: '3652', name: '名取岩沼農業協同組合' },
	{ code: '3653', name: 'みやぎ亘理農業協同組合' },
	{ code: '3665', name: 'みやぎ登米農業協同組合' },
	{ code: '3704', name: '古川農業協同組合' },
	{ code: '3710', name: '加美よつば農業協同組合' },
	{ code: '3721', name: '新みやぎ農業協同組合' },
	{ code: '3731', name: 'いしのまき農業協同組合' },
	{ code: '3751', name: 'みやぎ仙南農業協同組合' },
	{ code: '3762', name: 'かづの農業協同組合' },
	{ code: '3764', name: 'あきた北農業協同組合' },
	{ code: '3771', name: '秋田たかのす農業協同組合' },
	{ code: '3784', name: 'あきた白神農業協同組合' },
	{ code: '3795', name: '秋田やまもと農業協同組合' },
	{ code: '3798', name: 'あきた湖東農業協同組合' },
	{ code: '3810', name: '秋田なまはげ農業協同組合' },
	{ code: '3825', name: '秋田しんせい農業協同組合' },
	{ code: '3855', name: '秋田おばこ農業協同組合' },
	{ code: '3878', name: '秋田ふるさと農業協同組合' },
	{ code: '3913', name: 'こまち農業協同組合' },
	{ code: '3917', name: 'うご農業協同組合' },
	{ code: '3929', name: '大潟村農業協同組合' },
	{ code: '3931', name: '山形市農業協同組合' },
	{ code: '3932', name: '山形農業協同組合' },
	{ code: '3938', name: '天童市農業協同組合' },
	{ code: '3943', name: 'さがえ西村山農業協同組合' },
	{ code: '3960', name: 'みちのく村山農業協同組合' },
	{ code: '3962', name: '東根市農業協同組合' },
	{ code: '3971', name: '新庄市農業協同組合' },
	{ code: '3973', name: 'もがみ中央農業協同組合' },
	{ code: '3987', name: '金山農業協同組合' },
	{ code: '3989', name: '山形おきたま農業協同組合' },
	{ code: '4000', name: '鶴岡市農業協同組合' },
	{ code: '4013', name: '庄内たがわ農業協同組合' },
	{ code: '4022', name: '余目町農業協同組合' },
	{ code: '4027', name: '庄内みどり農業協同組合' },
	{ code: '4036', name: '酒田市袖浦農業協同組合' },
	{ code: '4047', name: 'ふくしま未来農業協同組合' },
	{ code: '4091', name: '夢みなみ農業協同組合' },
	{ code: '4132', name: '東西しらかわ農業協同組合' },
	{ code: '4160', name: '会津よつば農業協同組合' },
	{ code: '4196', name: '福島さくら農業協同組合' },
	{ code: '4238', name: '水戸農業協同組合' },
	{ code: '4263', name: '常陸農業協同組合' },
	{ code: '4294', name: '日立市多賀農業協同組合' },
	{ code: '4295', name: '茨城旭村農業協同組合' },
	{ code: '4296', name: 'ほこた農業協同組合' },
	{ code: '4301', name: 'なめがたしおさい農業協同組合' },
	{ code: '4322', name: '稲敷農業協同組合' },
	{ code: '4344', name: '水郷つくば農業協同組合' },
	{ code: '4363', name: 'つくば市農業協同組合' },
	{ code: '4371', name: 'つくば市谷田部農業協同組合' },
	{ code: '4378', name: '茨城みなみ農業協同組合' },
	{ code: '4387', name: 'やさと農業協同組合' },
	{ code: '4394', name: '新ひたち野農業協同組合' },
	{ code: '4397', name: '北つくば農業協同組合' },
	{ code: '4413', name: '常総ひかり農業協同組合' },
	{ code: '4422', name: '茨城むつみ農業協同組合' },
	{ code: '4425', name: '岩井農業協同組合' },
	{ code: '4445', name: '宇都宮農業協同組合' },
	{ code: '4456', name: '上都賀農業協同組合' },
	{ code: '4463', name: 'はが野農業協同組合' },
	{ code: '4478', name: '下野農業協同組合' },
	{ code: '4490', name: '小山農業協同組合' },
	{ code: '4497', name: '塩野谷農業協同組合' },
	{ code: '4507', name: '那須野農業協同組合' },
	{ code: '4518', name: '那須南農業協同組合' },
	{ code: '4523', name: '佐野農業協同組合' },
	{ code: '4533', name: '足利市農業協同組合' },
	{ code: '4540', name: '赤城橘農業協同組合' },
	{ code: '4544', name: '前橋市農業協同組合' },
	{ code: '4563', name: '高崎市農業協同組合' },
	{ code: '4567', name: 'はぐくみ農業協同組合' },
	{ code: '4593', name: '北群渋川農業協同組合' },
	{ code: '4594', name: '多野藤岡農業協同組合' },
	{ code: '4608', name: '甘楽富岡農業協同組合' },
	{ code: '4613', name: '碓氷安中農業協同組合' },
	{ code: '4626', name: 'あがつま農業協同組合' },
	{ code: '4628', name: '嬬恋村農業協同組合' },
	{ code: '4632', name: '利根沼田農業協同組合' },
	{ code: '4652', name: '佐波伊勢崎農業協同組合' },
	{ code: '4664', name: '新田みどり農業協同組合' },
	{ code: '4665', name: '太田市農業協同組合' },
	{ code: '4677', name: '邑楽館林農業協同組合' },
	{ code: '4682', name: 'さいたま農業協同組合' },
	{ code: '4730', name: 'あさか野農業協同組合' },
	{ code: '4735', name: 'いるま野農業協同組合' },
	{ code: '4780', name: '埼玉中央農業協同組合' },
	{ code: '4792', name: 'ちちぶ農業協同組合' },
	{ code: '4802', name: '埼玉ひびきの農業協同組合' },
	{ code: '4808', name: 'くまがや農業協同組合' },
	{ code: '4820', name: '埼玉岡部農業協同組合' },
	{ code: '4823', name: '花園農業協同組合' },
	{ code: '4828', name: 'ほくさい農業協同組合' },
	{ code: '4847', name: '越谷市農業協同組合' },
	{ code: '4848', name: '南彩農業協同組合' },
	{ code: '4859', name: '埼玉みずほ農業協同組合' },
	{ code: '4864', name: 'さいかつ農業協同組合' },
	{ code: '4874', name: 'ふかや農業協同組合' },
	{ code: '4876', name: '安房農業協同組合' },
	{ code: '4893', name: 'いすみ農業協同組合' },
	{ code: '4902', name: '木更津市農業協同組合' },
	{ code: '4909', name: '君津市農業協同組合' },
	{ code: '4916', name: '長生農業協同組合' },
	{ code: '4929', name: '山武郡市農業協同組合' },
	{ code: '4949', name: '市原市農業協同組合' },
	{ code: '4954', name: '千葉みらい農業協同組合' },
	{ code: '4955', name: '八千代市農業協同組合' },
	{ code: '4959', name: '市川市農業協同組合' },
	{ code: '4965', name: 'とうかつ中央農業協同組合' },
	{ code: '4975', name: 'ちば東葛農業協同組合' },
	{ code: '4992', name: '成田市農業協同組合' },
	{ code: '4993', name: '富里市農業協同組合' },
	{ code: '4996', name: '西印旛農業協同組合' },
	{ code: '5000', name: 'かとり農業協同組合' },
	{ code: '5016', name: 'ちばみどり農業協同組合' },
	{ code: '5030', name: '西東京農業協同組合' },
	{ code: '5037', name: '西多摩農業協同組合' },
	{ code: '5039', name: '秋川農業協同組合' },
	{ code: '5050', name: '八王子市農業協同組合' },
	{ code: '5055', name: '東京南農業協同組合' },
	{ code: '5060', name: '町田市農業協同組合' },
	{ code: '5070', name: 'マインズ農業協同組合' },
	{ code: '5072', name: '東京みどり農業協同組合' },
	{ code: '5077', name: '東京みらい農業協同組合' },
	{ code: '5087', name: '東京むさし農業協同組合' },
	{ code: '5094', name: '東京中央農業協同組合' },
	{ code: '5095', name: '世田谷目黒農業協同組合' },
	{ code: '5097', name: '東京あおば農業協同組合' },
	{ code: '5100', name: '東京スマイル農業協同組合' },
	{ code: '5114', name: '横浜農業協同組合' },
	{ code: '5123', name: 'セレサ川崎農業協同組合' },
	{ code: '5128', name: 'よこすか葉山農業協同組合' },
	{ code: '5130', name: '三浦市農業協同組合' },
	{ code: '5131', name: 'さがみ農業協同組合' },
	{ code: '5137', name: '湘南農業協同組合' },
	{ code: '5140', name: '秦野市農業協同組合' },
	{ code: '5147', name: 'かながわ西湘農業協同組合' },
	{ code: '5152', name: '厚木市農業協同組合' },
	{ code: '5153', name: '県央愛川農業協同組合' },
	{ code: '5159', name: '相模原市農業協同組合' },
	{ code: '5162', name: '神奈川つくい農業協同組合' },
	{ code: '5169', name: 'フルーツ山梨農業協同組合' },
	{ code: '5199', name: '笛吹農業協同組合' },
	{ code: '5207', name: '山梨みらい農業協同組合' },
	{ code: '5243', name: '南アルプス市農業協同組合' },
	{ code: '5260', name: '梨北農業協同組合' },
	{ code: '5272', name: 'クレイン農業協同組合' },
	{ code: '5284', name: '北富士農業協同組合' },
	{ code: '5287', name: '鳴沢村農業協同組合' },
	{ code: '5311', name: '長野八ヶ岳農業協同組合' },
	{ code: '5335', name: '佐久浅間農業協同組合' },
	{ code: '5348', name: '信州うえだ農業協同組合' },
	{ code: '5372', name: '信州諏訪農業協同組合' },
	{ code: '5384', name: '上伊那農業協同組合' },
	{ code: '5405', name: 'みなみ信州農業協同組合' },
	{ code: '5441', name: '木曽農業協同組合' },
	{ code: '5448', name: '松本ハイランド農業協同組合' },
	{ code: '5462', name: '洗馬農業協同組合' },
	{ code: '5466', name: 'あづみ農業協同組合' },
	{ code: '5470', name: '大北農業協同組合' },
	{ code: '5477', name: 'グリーン長野農業協同組合' },
	{ code: '5491', name: '中野市農業協同組合' },
	{ code: '5499', name: 'ながの農業協同組合' },
	{ code: '5541', name: '北蒲みなみ農業協同組合' },
	{ code: '5542', name: 'ささかみ農業協同組合' },
	{ code: '5554', name: '北越後農業協同組合' },
	{ code: '5568', name: '胎内市農業協同組合' },
	{ code: '5577', name: '新潟みらい農業協同組合' },
	{ code: '5585', name: '新津さつき農業協同組合' },
	{ code: '5600', name: '新潟かがやき農業協同組合' },
	{ code: '5631', name: 'にいがた南蒲農業協同組合' },
	{ code: '5666', name: 'えちご中越農業協同組合' },
	{ code: '5685', name: '越後さんとう農業協同組合' },
	{ code: '5690', name: '越後おぢや農業協同組合' },
	{ code: '5693', name: '北魚沼農業協同組合' },
	{ code: '5707', name: 'みなみ魚沼農業協同組合' },
	{ code: '5714', name: '十日町農業協同組合' },
	{ code: '5719', name: '津南町農業協同組合' },
	{ code: '5720', name: '柏崎農業協同組合' },
	{ code: '5768', name: 'えちご上越農業協同組合' },
	{ code: '5797', name: 'ひすい農業協同組合' },
	{ code: '5815', name: 'かみはやし農業協同組合' },
	{ code: '5823', name: 'にいがた岩船農業協同組合' },
	{ code: '5832', name: '佐渡農業協同組合' },
	{ code: '5847', name: '羽茂農業協同組合' },
	{ code: '5864', name: '新潟市農業協同組合' },
	{ code: '5877', name: 'みな穂農業協同組合' },
	{ code: '5883', name: '黒部市農業協同組合' },
	{ code: '5885', name: '魚津市農業協同組合' },
	{ code: '5888', name: 'アルプス農業協同組合' },
	{ code: '5895', name: 'あおば農業協同組合' },
	{ code: '5897', name: '富山市農業協同組合' },
	{ code: '5898', name: 'なのはな農業協同組合' },
	{ code: '5906', name: '山田村農業協同組合' },
	{ code: '5911', name: 'いみず野農業協同組合' },
	{ code: '5916', name: '高岡市農業協同組合' },
	{ code: '5920', name: '氷見市農業協同組合' },
	{ code: '5921', name: 'となみ野農業協同組合' },
	{ code: '5927', name: 'なんと農業協同組合' },
	{ code: '5932', name: 'いなば農業協同組合' },
	{ code: '5935', name: '福光農業協同組合' },
	{ code: '5943', name: '加賀農業協同組合' },
	{ code: '5962', name: '小松市農業協同組合' },
	{ code: '5980', name: '根上農業協同組合' },
	{ code: '5982', name: '能美農業協同組合' },
	{ code: '5997', name: '松任市農業協同組合' },
	{ code: '6010', name: '野々市農業協同組合' },
	{ code: '6012', name: '白山農業協同組合' },
	{ code: '6024', name: '金沢中央農業協同組合' },
	{ code: '6025', name: '金沢市農業協同組合' },
	{ code: '6062', name: '石川かほく農業協同組合' },
	{ code: '6076', name: 'はくい農業協同組合' },
	{ code: '6084', name: '志賀農業協同組合' },
	{ code: '6094', name: '能登わかば農業協同組合' },
	{ code: '6113', name: 'おおぞら農業協同組合' },
	{ code: '6121', name: '内浦町農業協同組合' },
	{ code: '6122', name: '珠洲市農業協同組合' },
	{ code: '6129', name: 'ぎふ農業協同組合' },
	{ code: '6175', name: '西美濃農業協同組合' },
	{ code: '6198', name: 'いび川農業協同組合' },
	{ code: '6242', name: 'めぐみの農業協同組合' },
	{ code: '6265', name: '陶都信用農業協同組合' },
	{ code: '6287', name: '東美濃農業協同組合' },
	{ code: '6313', name: '飛騨農業協同組合' },
	{ code: '6328', name: '伊豆太陽農業協同組合' },
	{ code: '6333', name: '三島函南農業協同組合' },
	{ code: '6338', name: '伊豆の国農業協同組合' },
	{ code: '6342', name: 'あいら伊豆農業協同組合' },
	{ code: '6345', name: '富士伊豆農業協同組合' },
	{ code: '6351', name: '御殿場農業協同組合' },
	{ code: '6355', name: '富士市農業協同組合' },
	{ code: '6357', name: '富士宮農業協同組合' },
	{ code: '6363', name: '清水農業協同組合' },
	{ code: '6373', name: '静岡市農業協同組合' },
	{ code: '6377', name: '大井川農業協同組合' },
	{ code: '6382', name: 'ハイナン農業協同組合' },
	{ code: '6386', name: '掛川市農業協同組合' },
	{ code: '6387', name: '遠州夢咲農業協同組合' },
	{ code: '6391', name: '遠州中央農業協同組合' },
	{ code: '6403', name: 'とぴあ浜松農業協同組合' },
	{ code: '6423', name: '三ヶ日町農業協同組合' },
	{ code: '6430', name: 'なごや農業協同組合' },
	{ code: '6436', name: '天白信用農業協同組合' },
	{ code: '6443', name: '緑信用農業協同組合' },
	{ code: '6451', name: '尾張中央農業協同組合' },
	{ code: '6456', name: '西春日井農業協同組合' },
	{ code: '6466', name: 'あいち尾東農業協同組合' },
	{ code: '6470', name: '愛知北農業協同組合' },
	{ code: '6483', name: '愛知西農業協同組合' },
	{ code: '6503', name: '海部東農業協同組合' },
	{ code: '6514', name: 'あいち海部農業協同組合' },
	{ code: '6531', name: 'あいち知多農業協同組合' },
	{ code: '6552', name: 'あいち中央農業協同組合' },
	{ code: '6560', name: '西三河農業協同組合' },
	{ code: '6572', name: 'あいち三河農業協同組合' },
	{ code: '6582', name: 'あいち豊田農業協同組合' },
	{ code: '6591', name: '愛知東農業協同組合' },
	{ code: '6606', name: '蒲郡市農業協同組合' },
	{ code: '6612', name: 'ひまわり農業協同組合' },
	{ code: '6615', name: '愛知みなみ農業協同組合' },
	{ code: '6618', name: '豊橋農業協同組合' },
	{ code: '6649', name: '三重北農業協同組合' },
	{ code: '6665', name: '鈴鹿農業協同組合' },
	{ code: '6673', name: '津安芸農業協同組合' },
	{ code: '6677', name: 'みえなか農業協同組合' },
	{ code: '6697', name: '多気郡農業協同組合' },
	{ code: '6731', name: '伊勢農業協同組合' },
	{ code: '6758', name: '伊賀ふるさと農業協同組合' },
	{ code: '6785', name: '福井県農業協同組合' },
	{ code: '6853', name: '越前たけふ農業協同組合' },
	{ code: '6874', name: 'レーク滋賀農業協同組合' },
	{ code: '6889', name: '甲賀農業協同組合' },
	{ code: '6897', name: 'グリーン近江農業協同組合' },
	{ code: '6900', name: '滋賀蒲生町農業協同組合' },
	{ code: '6909', name: '東能登川農業協同組合' },
	{ code: '6911', name: '湖東農業協同組合' },
	{ code: '6912', name: '東びわこ農業協同組合' },
	{ code: '6919', name: 'レーク伊吹農業協同組合' },
	{ code: '6924', name: '北びわこ農業協同組合' },
	{ code: '6941', name: '京都市農業協同組合' },
	{ code: '6956', name: '京都中央農業協同組合' },
	{ code: '6961', name: '京都やましろ農業協同組合' },
	{ code: '6990', name: '京都農業協同組合' },
	{ code: '6996', name: '京都丹の国農業協同組合' },
	{ code: '7025', name: '北大阪農業協同組合' },
	{ code: '7029', name: '高槻市農業協同組合' },
	{ code: '7032', name: '茨木市農業協同組合' },
	{ code: '7041', name: '大阪北部農業協同組合' },
	{ code: '7087', name: '大阪泉州農業協同組合' },
	{ code: '7092', name: 'いずみの農業協同組合' },
	{ code: '7111', name: '堺市農業協同組合' },
	{ code: '7139', name: '大阪南農業協同組合' },
	{ code: '7156', name: 'グリーン大阪農業協同組合' },
	{ code: '7164', name: '大阪中河内農業協同組合' },
	{ code: '7184', name: '大阪東部農業協同組合' },
	{ code: '7191', name: '九個荘農業協同組合' },
	{ code: '7193', name: '北河内農業協同組合' },
	{ code: '7200', name: '大阪市農業協同組合' },
	{ code: '7213', name: '兵庫六甲農業協同組合' },
	{ code: '7239', name: 'あかし農業協同組合' },
	{ code: '7240', name: '兵庫南農業協同組合' },
	{ code: '7249', name: 'みのり農業協同組合' },
	{ code: '7264', name: '兵庫みらい農業協同組合' },
	{ code: '7274', name: '加古川市南農業協同組合' },
	{ code: '7288', name: '兵庫西農業協同組合' },
	{ code: '7316', name: '相生市農業協同組合' },
	{ code: '7326', name: 'ハリマ農業協同組合' },
	{ code: '7338', name: 'たじま農業協同組合' },
	{ code: '7353', name: '丹波ひかみ農業協同組合' },
	{ code: '7362', name: '丹波ささやま農業協同組合' },
	{ code: '7363', name: '淡路日の出農業協同組合' },
	{ code: '7373', name: 'あわじ島農業協同組合' },
	{ code: '7387', name: '奈良県農業協同組合' },
	{ code: '7532', name: 'わかやま農業協同組合' },
	{ code: '7541', name: 'ながみね農業協同組合' },
	{ code: '7543', name: '紀の里農業協同組合' },
	{ code: '7550', name: '紀北川上農業協同組合' },
	{ code: '7559', name: 'ありだ農業協同組合' },
	{ code: '7565', name: '紀州農業協同組合' },
	{ code: '7576', name: '紀南農業協同組合' },
	{ code: '7591', name: 'みくまの農業協同組合' },
	{ code: '7601', name: '鳥取いなば農業協同組合' },
	{ code: '7625', name: '鳥取中央農業協同組合' },
	{ code: '7641', name: '鳥取西部農業協同組合' },
	{ code: '7708', name: '島根県農業協同組合' },
	{ code: '7755', name: '岡山市農業協同組合' },
	{ code: '7837', name: '晴れの国岡山農業協同組合' },
	{ code: '7909', name: '広島市農業協同組合' },
	{ code: '7913', name: '呉農業協同組合' },
	{ code: '7916', name: '安芸農業協同組合' },
	{ code: '7938', name: '佐伯中央農業協同組合' },
	{ code: '7981', name: '広島北部農業協同組合' },
	{ code: '7994', name: '広島中央農業協同組合' },
	{ code: '8011', name: '芸南農業協同組合' },
	{ code: '8019', name: '広島ゆたか農業協同組合' },
	{ code: '8027', name: '三原農業協同組合' },
	{ code: '8029', name: '尾道市農業協同組合' },
	{ code: '8047', name: '福山市農業協同組合' },
	{ code: '8069', name: '三次農業協同組合' },
	{ code: '8076', name: '庄原農業協同組合' },
	{ code: '8134', name: '山口県農業協同組合' },
	{ code: '8231', name: '徳島市農業協同組合' },
	{ code: '8234', name: '東とくしま農業協同組合' },
	{ code: '8242', name: '名西郡農業協同組合' },
	{ code: '8252', name: '板野郡農業協同組合' },
	{ code: '8257', name: '徳島北農業協同組合' },
	{ code: '8261', name: '大津松茂農業協同組合' },
	{ code: '8263', name: '里浦農業協同組合' },
	{ code: '8268', name: '阿南農業協同組合' },
	{ code: '8288', name: 'かいふ農業協同組合' },
	{ code: '8296', name: '阿波市農業協同組合' },
	{ code: '8305', name: '麻植郡農業協同組合' },
	{ code: '8312', name: '美馬農業協同組合' },
	{ code: '8323', name: '阿波みよし農業協同組合' },
	{ code: '8332', name: '香川県農業協同組合' },
	{ code: '8389', name: 'うま農業協同組合' },
	{ code: '8397', name: 'えひめ未来農業協同組合' },
	{ code: '8398', name: '周桑農業協同組合' },
	{ code: '8400', name: '越智今治農業協同組合' },
	{ code: '8401', name: '今治立花農業協同組合' },
	{ code: '8425', name: '松山市農業協同組合' },
	{ code: '8457', name: '愛媛たいき農業協同組合' },
	{ code: '8463', name: '西宇和農業協同組合' },
	{ code: '8477', name: '東宇和農業協同組合' },
	{ code: '8482', name: 'えひめ南農業協同組合' },
	{ code: '8500', name: 'えひめ中央農業協同組合' },
	{ code: '8551', name: '高知市農業協同組合' },
	{ code: '8582', name: '高知県農業協同組合' },
	{ code: '8589', name: '土佐くろしお農業協同組合' },
	{ code: '8621', name: '宗像農業協同組合' },
	{ code: '8626', name: '粕屋農業協同組合' },
	{ code: '8632', name: '福岡市東部農業協同組合' },
	{ code: '8633', name: '福岡市農業協同組合' },
	{ code: '8635', name: '糸島農業協同組合' },
	{ code: '8636', name: '筑紫農業協同組合' },
	{ code: '8645', name: '筑前あさくら農業協同組合' },
	{ code: '8653', name: 'にじ農業協同組合' },
	{ code: '8656', name: 'みい農業協同組合' },
	{ code: '8660', name: '久留米市農業協同組合' },
	{ code: '8664', name: '三潴町農業協同組合' },
	{ code: '8667', name: '福岡大城農業協同組合' },
	{ code: '8668', name: '福岡八女農業協同組合' },
	{ code: '8680', name: '柳川農業協同組合' },
	{ code: '8689', name: '南筑後農業協同組合' },
	{ code: '8692', name: '北九州農業協同組合' },
	{ code: '8694', name: '直鞍農業協同組合' },
	{ code: '8701', name: '福岡嘉穂農業協同組合' },
	{ code: '8715', name: '田川農業協同組合' },
	{ code: '8730', name: '福岡京築農業協同組合' },
	{ code: '8740', name: '佐賀市中央農業協同組合' },
	{ code: '8762', name: '佐賀県農業協同組合' },
	{ code: '8766', name: '唐津農業協同組合' },
	{ code: '8771', name: '伊万里市農業協同組合' },
	{ code: '8794', name: '長崎西彼農業協同組合' },
	{ code: '8813', name: '長崎県央農業協同組合' },
	{ code: '8829', name: '島原雲仙農業協同組合' },
	{ code: '8857', name: 'ながさき西海農業協同組合' },
	{ code: '8893', name: 'ごとう農業協同組合' },
	{ code: '8905', name: '壱岐市農業協同組合' },
	{ code: '8906', name: '対馬農業協同組合' },
	{ code: '8916', name: '熊本市農業協同組合' },
	{ code: '8926', name: '玉名農業協同組合' },
	{ code: '8941', name: '鹿本農業協同組合' },
	{ code: '8949', name: '菊池地域農業協同組合' },
	{ code: '8964', name: '阿蘇農業協同組合' },
	{ code: '8982', name: '上益城農業協同組合' },
	{ code: '9010', name: '熊本宇城農業協同組合' },
	{ code: '9017', name: '八代地域農業協同組合' },
	{ code: '9043', name: 'あしきた農業協同組合' },
	{ code: '9048', name: '球磨地域農業協同組合' },
	{ code: '9069', name: '本渡五和農業協同組合' },
	{ code: '9070', name: 'あまくさ農業協同組合' },
	{ code: '9072', name: '苓北町農業協同組合' },
	{ code: '9103', name: 'べっぷ日出農業協同組合' },
	{ code: '9104', name: '大分県農業協同組合' },
	{ code: '9145', name: '大分大山町農業協同組合' },
	{ code: '9169', name: '宮崎中央農業協同組合' },
	{ code: '9177', name: '綾町農業協同組合' },
	{ code: '9178', name: 'はまゆう農業協同組合' },
	{ code: '9181', name: '串間市大束農業協同組合' },
	{ code: '9184', name: '都城農業協同組合' },
	{ code: '9193', name: 'こばやし農業協同組合' },
	{ code: '9197', name: 'えびの市農業協同組合' },
	{ code: '9200', name: '児湯農業協同組合' },
	{ code: '9203', name: '尾鈴農業協同組合' },
	{ code: '9205', name: '西都農業協同組合' },
	{ code: '9208', name: '延岡農業協同組合' },
	{ code: '9213', name: '日向農業協同組合' },
	{ code: '9221', name: '高千穂地区農業協同組合' },
	{ code: '9229', name: '鹿児島みらい農業協同組合' },
	{ code: '9251', name: 'いぶすき農業協同組合' },
	{ code: '9257', name: '南さつま農業協同組合' },
	{ code: '9270', name: 'さつま日置農業協同組合' },
	{ code: '9296', name: '北さつま農業協同組合' },
	{ code: '9302', name: '鹿児島いずみ農業協同組合' },
	{ code: '9319', name: 'あいら農業協同組合' },
	{ code: '9332', name: 'そお鹿児島農業協同組合' },
	{ code: '9338', name: 'あおぞら農業協同組合' },
	{ code: '9341', name: '鹿児島きもつき農業協同組合' },
	{ code: '9347', name: '肝付吾平町農業協同組合' },
	{ code: '9353', name: '種子屋久農業協同組合' },
	{ code: '9363', name: 'あまみ農業協同組合' },
	{ code: '9375', name: '沖縄県農業協同組合' },
];
