import { BannerVariant } from '@krakentech/coral';
import { FC } from 'react';

import { useStoryblokBannersContext } from '@/components/contexts/storyblokBanners';
import { CustomBanner } from '@/components/storyblok/bloks/banner-components/CustomBanner';

type CustomBannerHandlerProps = {
	page: 'obj' | 'account' | 'landing' | 'friend-index' | 'friend-code';
	slug: string;
	variant?: BannerVariant;
};

export const CustomBannerHandler: FC<CustomBannerHandlerProps> = ({
	slug,
	page,
	variant,
}) => {
	const storyblokBannersContext = useStoryblokBannersContext();

	const bannerStory = storyblokBannersContext.bannerStories.find(
		(story) =>
			story?.slug === slug && story.content?.where_to_display?.includes(page)
	);
	return bannerStory?.content ? (
		<div className="w-full">
			<CustomBanner content={bannerStory?.content} variant={variant} />
		</div>
	) : null;
};
