import { createContext, useContext } from 'react';

import { BannerItemFragment } from '@/services/graphql-storyblok';

type StoryblokBannersContextValue = {
	bannerStories: BannerItemFragment[];
};

export const StoryblokBannersContext =
	createContext<StoryblokBannersContextValue>({ bannerStories: [] });

export const useStoryblokBannersContext = (): StoryblokBannersContextValue =>
	useContext(StoryblokBannersContext);
